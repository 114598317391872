.footer-container {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 3;
  transition: background-color 0.3s;
  background-color: rgba(248, 248, 248, 1); /* Fully solid when not scrolled */
  margin-top: 20;
}

.footer-container.scrolled {
  background-color: rgba(248, 248, 248, 0.5); /* Semi-transparent when scrolled */
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 20px;
  width: 100%;
  background-color: transparent;
}

.footer-menu {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.footer-menu .nav-link {
  color: #333;
  text-decoration: none;
}

.footer-menu .nav-link:hover,
.footer-menu .nav-link:focus {
  color: #333;
  text-decoration: underline;
}

.footer-social {
  display: flex;
  gap: 10px;
  align-items: center;
  flex-wrap: wrap;
}

.footer-social a {
  color: #333;
  text-decoration: none;
}

.footer-icon {
  width: 24px;
  height: 24px;
}

/* Additional styles from Footer.css */

body {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.container {
  flex: 1;
}
