.home {
  text-align: center;
}

.home-title {
  font-size: 48px;
  margin-bottom: 20px;
}

.home-title.small {
  font-size: 36px;
}

.home-image {
  max-width: 100%;
  height: auto;
  margin-bottom: 20px;
}

.home-content {
  max-width: 600px;
  margin: 0 auto;
  padding: 0 20px;
}

.home-content.small {
  max-width: 100%;
}

.smart-section {
  margin-top: 5rem;
}

@media (max-width: 768px) {
  .home-title {
    font-size: 36px;
  }

  .home-title.small {
    font-size: 24px;
  }

  .home-content {
    max-width: 100%;
  }
}

