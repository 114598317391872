.not-found {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 32px;
    text-align: center;
    background-color: #f5f5f5;
  }
  
  .not-found-title {
    font-size: 48px;
    margin-bottom: 16px;
    color: #333;
  }
  
  .not-found-text {
    font-size: 24px;
    margin-bottom: 32px;
    color: #666;
  }
  
  .not-found-link {
    padding: 8px 16px;
    border-radius: 4px;
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
    color: #fff;
    background-color: #007bff;
    border: none;
    cursor: pointer;
    transition: background-color 0.2s ease;
  }
  
  .not-found-link:hover {
    background-color: #0069d9;
  }
  