/* Define global styles here (if any) */

/* Styles for the .mint-form-container */
.mint-form-container {
    display: flex;
    justify-content: center;
    align-items: center;


    margin-top: 20;
    margin-left: 100;
  }
  
  /* Styles for the .ask section and its children */
  .ask {
    /* Make the section wider and set the background image properties */
    width: 100%;
    max-width: 1200px; /* Adjust this width as needed */
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    margin-top: 1000;
    /* If you want the image to cover the entire page, use the following:
    min-height: 100vh; */
  }
  
  .ask__area {
    max-width: 800px; /* Adjust the max-width as needed */
    width: 90%;
    padding: 20px;
    border: 1px solid #cccccc;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
  }
  
  .ask .section__header {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .ask form {
    display: flex;
    flex-direction: column;
  }
  
  .ask .input {
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
  }
  
  .ask .input label {
    font-weight: bold;
    margin-bottom: 5px; /* Optional: Add spacing between label and input field */
  }
  
  .ask .input input,
  .ask .input textarea {
    padding: 10px;
    border: 1px solid #cccccc;
    border-radius: 4px;
    font-size: 14px;
    /* Make the input fields wider */
    width: 100%; /* This will make the input fields take up the full width */
  }
  
  .ask .button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    color: #ffffff;
    font-size: 14px;
    background-color: #007bff;
  }
  
  .ask .button:hover {
    background-color: #0056b3;
  }
  
  .ask .error {
    color: #ff0000;
    font-size: 14px;
    margin-top: 10px;
  }
  
  .ask .success {
    color: #00cc00;
    font-size: 14px;
    margin-top: 10px;
  }
  
  .ask .image-preview img {
    margin-top: 10px;
    max-width: 100%;
    max-height: 300px;
    border: 1px solid #cccccc;
    border-radius: 4px;
  }
  
  /* The rest of the CSS remains the same as in the previous response */
  