.terms-container {
    margin: 30px;
  }
  
  .terms-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
    text-align: left;
  }
  
  .terms-content {
    font-size: 16px;
    line-height: 1.6;
    text-align: left;
  }
  