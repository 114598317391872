.nft-list-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    padding: 20px;
  }
  
  .nft-card {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .nft-title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .nft-owner {
    font-size: 14px;
    color: #888;
    margin-bottom: 10px;
  }
  
  .nft-image {
    width: 100%;
    height: auto;
    border-radius: 4px;
  }
  
  .nft-description {
    font-size: 14px;
    margin-bottom: 10px;
  }
  