.tokenomics-page {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .section {
    margin-bottom: 40px;
  }
  
  h1 {
    text-align: center;
  }
  
  h2 {
    color: #333;
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  p {
    color: #666;
    font-size: 16px;
    line-height: 1.6;
  }
  