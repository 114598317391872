.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding-top: 70px; /* height of your header */
  padding-bottom: 70px; /* height of your footer */
  background-image: url('./pages/images/hero-two-bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  box-sizing: border-box; /* to include padding in the element's total height and width */
}

.app-body {
  padding-top: 150px; /* height of your header */
  padding-bottom: 150px; /* height of your footer */
}


.banner {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0 0px; /* Adjust the padding as needed */
  padding-left: 35px;
  padding-top: 5px;
}

.banner-content {
  max-width: 600px;
  width: 100%; /* Added width to make it fill the container */
  margin: 0 auto; /* Center the content horizontally */
}

.banner-title {
  font-size: 48px;
  margin-bottom: 20px;
}

.banner-subtitle {
  font-size: 24px;
  margin-bottom: 20px;
}

.banner-description {
  font-size: 16px;
  margin-bottom: 20px;
}

.explore-button {
  font-size: 18px;
  padding: 12px 24px;
  background-color: #2987d4;
  color: #fff;
  border: none;
  border-radius: 4px;
}

.smart-section {
  margin: 80px 0; /* Adjust the margin as needed */
  margin-left: 70px;
  margin-right: 70px;
}

@media screen and (max-width: 768px) {
  .banner {
    padding: 16px;
  }

  .banner-title {
    font-size: 36px;
  }

  .banner-subtitle {
    font-size: 20px;
  }

  .banner-description {
    font-size: 14px;
  }
}
