.header-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  transition: background-color 0.3s;
  background-color: rgba(248, 248, 248, 1); /* Fully solid when not scrolled */
  margin-bottom: 20px;
  z-index: 1;
}

.header-container.scrolled {
  background-color: soli
}

.nav-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav-menu {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
}

.nav-menu .nav-item {
  margin-right: 20px;
  display: flex;
  align-items: center;
}

@media (max-width: 991.98px) {
  .nav-menu {
    margin-left: auto;
  }
}

.nav-menu .nav-item .nav-link {
  color: #333;
  text-decoration: none;
  border: none;
  text-decoration: none;
}

.nav-menu .nav-item .nav-item-button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  padding: 0 16px;
  border-radius: 16px;
  font-weight: bold;
  text-transform: uppercase;
  color: #fff;
  background-color: #007bff;
  border: none;
  cursor: pointer;
  transition: background-color 0.2s ease;
  z-index: 4;
}

.nav-menu .nav-item .nav-item-button:hover {
  background-color: #0056b3;
}

.wallet-connect-container {
  display: flex;
  align-items: center;
}

.wallet-connect.right {
  margin-left: auto;
}

.connect-button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
}

.connect-button:hover {
  background-color: #0056b3;
}
