.wallet-connect {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.connect-btn,
.disconnect-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  padding: 0 16px;
  border-radius: 16px;
  font-weight: bold;
  text-transform: uppercase;
  color: #fff;
  background-color: #007bff;
  border: none;
  cursor: pointer;
  z-index: 12; 
  transition: background-color 0.2s ease;
}

.connect-btn:hover,
.disconnect-btn:hover {
  background-color: #0069d9;
}

.address-box {
  display: flex;
  align-items: center;
  background-color: #007bff;
  color: #fff;
  border-radius: 16px;
  padding: 10px 20px;
  margin-bottom: 20px;
  margin-top: 20px;
}

.address-label {
  font-weight: bold;
  margin-right: 8px;
}

.address {
  font-family: monospace;
}

.modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  width: 500px;
  height: 300px; /* Add the desired height value */
  background-color: #b0e0e6;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: relative;
}

.modal-content h2 {
  font-size: 24px;
  margin-bottom: 20px;
}

.wallet-logo {
  width: 50px;
  height: 50px;
  margin-bottom: 10px;
}

.error-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #f8d7da;
  color: #721c24;
  padding: 20px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 999;
}

.error-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.error-content p {
  margin: 0;
  font-size: 16px;
  line-height: 1.5;
  margin-right: 10px;
}

.close-error-btn {
  font-size: 16px;
  padding: 8px 16px;
  border-radius: 4px;
  background-color: #dc3545;
  color: #fff;
  border: none;
  cursor: pointer;
}

.wallet-connect .modal-content button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  padding: 0 16px;
  margin-bottom: 10px;
  border-radius: 16px;
  font-weight: bold;
  text-transform: uppercase;
  color: #fff;
  background-color: #007bff;
  border: none;
  cursor: pointer;
  z-index: 12; 
  transition: background-color 0.2s ease;
}

.wallet-connect .modal-content button:hover {
  background-color: #0069d9;
  z-index: 12; 
}

.wallet-connect .modal-content button:first-child {
  background-color: #e20880;
  z-index: 12; 
}

.wallet-connect .modal-content button:first-child:hover {
  background-color: #c5076b;
  z-index: 12; 
}

.wallet-connect .modal-content button {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1; 
}

.wallet-connect .modal-content button .wallet-logo {
  margin-right: 10px;
  z-index: 1; 
}

.wallet-connect .modal-content button span {
  font-size: 14px;
  z-index: 1; 
}

.ReactModalPortal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 70%;
  height: 70%;
  z-index: 1; /* Set the value according to your need */
}

.modal-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.close-modal-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  color: #888;
  cursor: pointer;
  transition: color 0.2s ease;
}

.close-modal-btn:hover {
  color: #555;
}

.wallet-icons {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 20px;
  z-index: 20;
}

.wallet-icons .wallet-option {
  display: flex;
  align-items: center;
  margin: 8px 0;
  z-index: 20;
}

.wallet-icons .wallet-option .wallet-logo {
  width: 32px;
  height: 32px;
  margin-right: 10px;
}

.wallet-icons .wallet-option .wallet-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  padding: 0 16px;
  border-radius: 16px;
  font-weight: bold;
  text-transform: uppercase;
  color: #fff;
  background-color: #007bff;
  border: none;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.wallet-icons .wallet-option .wallet-btn:hover {
  background-color: #0069d9;
}
