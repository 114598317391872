.smart {
    text-align: center;
  }
  
  .smart-section {
    margin-top: 5rem;
    text-align: center;
  }
  
  .smart-title {
    font-size: 36px;
    margin-bottom: 20px;
    color: #007bff;
    margin-bottom: 100px;
  }
  
  .smart-images {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: -50px;
    margin-right: -50px;
  }
  
  .smart-image {
    flex: 1;
    margin: 0 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .smart-image img {
    width: 100px;
    height: auto;
    max-height: 100px;
  }
  
  .smart-image-title {
    font-size: 24px;
    margin-top: 10px;
    color: #333399; /* Dark blue purplish color */
  }
  