.dashboard {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 32px;
  text-align: center;
 margin-bottom: 30;
 
}

.dashboard-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 32px;
}

.dashboard-title {
  font-size: 36px;
  margin-right: 16px;
  color: #333;
}

.dashboard-balance {
  font-size: 24px;
  color: #333;
}

.dashboard-navigation {
  display: flex;
  justify-content: center;
  margin-bottom: 32px;
}

.dashboard-nav-list {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
}

.dashboard-nav-item {
  margin-right: 16px;
}

.dashboard-nav-item a {
  text-decoration: none;
  font-size: 18px;
  color: #333;
  transition: color 0.2s ease;
}

.dashboard-nav-item a:hover {
  color: #007bff;
}

.collection-heading {
  font-size: 24px;
  margin-bottom: 16px;
  color: #333;
  text-align: center;
}

.nft-collection {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin: 0 16px;
  width: calc(100% - 32px);
}

.nft-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  gap: 32px;
  max-width: 1200px;
  margin-top: 16px; /* Add margin-top to separate from the heading */
}

.nft-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 300px;
  padding: 16px;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.2s ease;

}

.nft-card:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.nft-image {
  width: 100%;
  height: auto;
  max-height: 200px;
  object-fit: cover;
}

.nft-details {
  margin-top: 16px;
  text-align: center;
}

.nft-description {
  font-size: 16px;
  color: #777;
}

.nft-id {
  font-size: 14px;
  color: #777;
}

.nft-manage-button {
  padding: 8px 16px;
  font-size: 16px;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.nft-manage-button:hover {
  background-color: #0056b3;
}

.nft-divider {
  width: 100%;
  height: 2px;
  background-color: black;
  margin-bottom: 16px;
}

.no-nfts {
  font-size: 16px;
  color: #777;
}
