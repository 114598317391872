.container {
  
    max-width: 600px;
    margin: 0 auto;
 
  }
  
  .title {
    text-align: center;
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .info {
    background-color: #f2f2f2;
    padding: 20px;
    margin-bottom: 20px;
    margin-top: 30;
  }
  
  .subtitle {
    margin: 10px 0;
  }
  
  .mint-section {
    display: flex;
    align-items: center;
  }
  
  .mint-title {
    margin-right: 10px;
  }
  
  .mint-input {
    padding: 5px;
    margin-right: 10px;
    width: 100px;
  }
  
  .mint-button {
    background-color: #4caf50;
    color: white;
    border: none;
    padding: 8px 16px;
    text-align: center;
    text-decoration: none;
    font-size: 14px;
    cursor: pointer;
  }
  
  .mint-button:disabled {
    background-color: #e0e0e0;
    cursor: not-allowed;
  }
  