.nft-details-container {
  padding: 200px;
}

.nft-media-container {
  margin-bottom: 20px;
}

.nft-video-container {
  position: relative;
  width: 100%;
  max-width: 800px;
  height: 450px;
  margin-top: 50px;
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.nft-info-container {
  margin-bottom: 20px;
}

.nft-name {
  font-size: 24px;
  margin-bottom: 10px;
}

.nft-description {
  margin-bottom: 10px;
}

.nft-traits-container {
  margin-bottom: 20px;
}

.nft-traits-heading {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.nft-traits-list {
  list-style: none;
  padding-left: 0;
}

.nft-trait-item {
  margin-bottom: 5px;
}

.nft-button-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 20px;
}

.nft-button {
  margin: 5px;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
}

.nft-button-confirm {
  background-color: #27ae60;
  color: #fff;
}

.nft-button-cancel {
  background-color: #e74c3c;
  color: #fff;
}

.nft-button-sell {
  background-color: #3498db;
  color: #fff;
}

.nft-button-rent {
  background-color: #f39c12;
  color: #fff;
}

.nft-button-end-rent {
  background-color: #e74c3c;
  color: #fff;
}

.nft-input-fields {
  margin-bottom: 10px;
}

.nft-input-label {
  display: block;
  font-size: 16px;
  margin-bottom: 5px;
}

.nft-input {
  width: 100%;
  padding: 5px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.status-message-container {
  margin-bottom: 20px;
  text-align: center;
  font-weight: bold;
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 20px;
}

/* Responsive styles */
@media screen and (max-width: 768px) {
  .nft-details-container {
    padding: 50px;
  }

  .nft-video-container {
    max-width: 100%;
    height: auto;
  }

  .nft-name, .nft-traits-heading {
    font-size: 20px;
  }

  .nft-description {
    font-size: 14px;
  }

  .nft-button {
    padding: 5px 10px;
    font-size: 14px;
  }

  .nft-input-label, .nft-input {
    font-size: 14px;
  }

  .loading-container {
    font-size: 16px;
  }
}
