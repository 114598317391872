.compliance-page {
    margin: 20px;
  }
  
  .compliance-header {
    text-align: center;
  }
  
  .compliance-title {
    font-size: 24px;
    margin: 0;
    padding: 20px;
  }
  
  .compliance-main {
    margin-top: 20px;
  }
  
  .compliance-section-title {
    font-size: 20px;
    margin: 0;
    margin-bottom: 10px;
  }
  
  .compliance-section-content {
    font-size: 16px;
    margin: 0;
    margin-bottom: 20px;
  }
  
  .compliance-footer {
    text-align: center;
    margin-top: 40px;
  }
  
  .compliance-footer-text {
    font-size: 14px;
    margin: 0;
  }
  
  a {
    color: #007bff;
    text-decoration: none;
  }
  
  a:hover {
    text-decoration: underline;
  }
  