.banner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  margin-right: 0;
}

  
  .banner-content {
    flex: 1;
    padding-right: 20px;
  }
  
  .banner-title {
    font-size: 36px;
    margin-bottom: 10px;
  }
  
  .banner-title-highlight {
    color: #007bff;
  }
  
  .banner-subtitle {
    font-size: 24px;
    margin-bottom: 10px;
    color: #333;
  }
  
  .banner-description {
    font-size: 18px;
    margin-bottom: 20px;
    color: #555;
  }
  
  .explore-button {
    padding: 10px 20px;
    font-size: 18px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .banner-image-container {
    flex: 1;
    text-align: right;
  }
  
  .banner-image {
    max-width: 100%;
    height: auto;
  }
  .banner-title {
    font-size: 48px;
    margin-bottom: 20px;
  }
  
  .banner-title-highlight-dark {
    color: #333399; /* Dark blue purplish color */
  }
  
  .banner-title-highlight-light {
    color: #007bff; /* Blue color */
  }
  
  .banner-subtitle {
    font-size: 24px;
    margin-bottom: 20px;
    color: #0099cc; /* Lighter blue color */
  }
  
  /* Rest of your CSS styles */
  
  