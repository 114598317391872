.rent-list-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  padding: 20px;
}

.nft-card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 5px;
  word-wrap: break-word;
  overflow-wrap: anywhere; /* Prevents long URLs from overflowing */
  max-width: 300px; /* Adjust the maximum width value as needed */
  position: relative;
}

.nft-card::before {
  content: "";
  position: absolute;
  left: -15px; /* Adjust the spacing as needed */
  top: 5px; /* Adjust the vertical positioning as needed */
  width: 6px; /* Adjust the size of the dot */
  height: 6px; /* Adjust the size of the dot */
  background-color: #f2f2f2; /* Set the color of the dot */
  border-radius: 50%; /* Shape the dot into a circle */
}

.nft-title {
  order: -1;  /* This will ensure the title is always first regardless of the HTML order */
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.nft-owner {
  font-size: 14px;
  color: #888;
  margin-bottom: 10px;
}

.nft-image {
  width: 100%;
  height: auto;
  border-radius: 4px;
}

.nft-description {
  font-size: 14px;
  margin-bottom: 10px;
}

.nft-button {
  padding: 12px 24px;
  background-color: #32CD32;
  color: white;
  font-weight: bold;
  border-radius: 4px;
  text-decoration: none;
  text-align: center;
  transition: background-color 0.3s ease;
  margin-right: 10px;
}

.nft-button-sell {
  background-color: #FF0000;
}

.nft-button:hover {
  background-color: #1b9db6;
}
