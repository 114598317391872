.roadmap-container {
    margin: 40px; /* Adjust margin according to your preference */
  }
  
  .roadmap-header {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .roadmap-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  
  .quarter {
    flex-basis: 23%;
    margin-bottom: 40px;
  }
  
  .year {
    flex-basis: 48%;
    margin-bottom: 40px;
  }
  
  .long-term {
    flex-basis: 100%;
    margin-bottom: 40px;
  }
  
  h1, h2 {
    text-align: left;
  }
  
  ul {
    margin-top: 10px;
    padding-left: 20px;
    line-height: 1.5;
  }
  